import React, {useEffect, useRef, useState} from 'react';
import {Col, Menu, Row} from "antd"
import {useTranslation} from "react-i18next";
import ARA_LOGO from '../images/ara_logo.png'
import {goToSection, updateHash} from "../utils";
import {sectionLayout} from "../utils/constants";
import {MenuOutlined} from "@ant-design/icons"

function NavBar() {
    const {t} = useTranslation()

    const defaultSection = 'intro'

    const navbarRef = useRef<HTMLDivElement | null>(null)
    const [currentSection, setCurrentSection] = useState<string>(defaultSection)
    const sectionRef = useRef(defaultSection)

    const sectionsRef = useRef<(HTMLElement | null)[]>([])

    function getCurrentSection() {
        const sections = ['intro','about', 'functionalities', 'recycling_guide', 'helpdesk', 'concurs']
        if(sectionsRef.current.length === 0 ){
            sectionsRef.current = sections.map(sectionId => document.getElementById(sectionId))
        }

        const topOffset = 200;
        for (let i = 0; i < sections.length; i++) {
            const sectionElement = sectionsRef.current[i]
            const topScroll = window.scrollY + topOffset
            if (!sectionElement || topScroll < sectionElement.offsetTop) {
                continue
            }
            const isLastItem = i === sections.length - 1
            if (isLastItem) {
                return sectionsRef.current[i]?.id || defaultSection
            }
            const nextElement = sectionsRef.current[i + 1]
            if (nextElement && nextElement.offsetTop > topScroll) {
                return sectionsRef.current[i]?.id || defaultSection
            }
        }
        return defaultSection
    }

    useEffect(() => {
        const scrollCallback = (event: Event) => {
            const opacity = Math.min(window.scrollY / 20, 1)
            if (navbarRef.current) {
                navbarRef.current.style.backgroundColor = `rgba(255, 255,255, ${opacity})`
                navbarRef.current.style.boxShadow = `0 8px 6px -6px rgba(200, 200, 200, ${opacity})`
            }

            const section = getCurrentSection()
            if(sectionRef.current !== section){
                sectionRef.current = section
                setCurrentSection(section)
                updateHash(section)
            }
        }

        window.addEventListener('scroll', scrollCallback, true)
        return () => {
            window.removeEventListener('scroll', scrollCallback, true)
        }
    }, [getCurrentSection])

    return (
        <Row className='navbar-container' ref={navbarRef}>
            <Col {...sectionLayout}>
                <Row justify='space-between'>
                    <Col span={4}>
                        <img src={ARA_LOGO}
                             alt="ara logo"
                             style={{width: "132px", cursor: 'pointer'}}
                             onClick={() => goToSection('intro')}/>
                    </Col>
                    <Col
                        xs={{span: 4, offset: 0}}
                        sm={{span: 16, offset: 0}}>
                        <Menu id="navbar"
                              mode="horizontal"
                              activeKey={currentSection}
                              selectedKeys={[currentSection]}
                              overflowedIndicator={<MenuOutlined/>}
                              className='navbar'>
                            <Menu.Item key="about"
                                       onClick={() => goToSection('about')}>
                                {t('navbar.about')}
                            </Menu.Item>
                            <Menu.Item key="functionalities"
                                       onClick={() => goToSection('functionalities')}>
                                {t('navbar.functionalities')}
                            </Menu.Item>
                            <Menu.Item key="recycling_guide"
                                       onClick={() => goToSection('recycling_guide')}>
                                {t('navbar.recycling_guide')}
                            </Menu.Item>
                            <Menu.Item key="helpdesk"
                                       onClick={() => goToSection('helpdesk')}>
                                {t('navbar.help_desk')}
                            </Menu.Item>
                {/* <Menu.Item key="turorial"
                  onClick={() => goToSection('tutorial/start')}>
                  {t('navbar.tutorial')}
                </Menu.Item> */}
                            {/*<Menu.Item key="contact">*/}
                            {/*    {t('navbar.contact')}*/}
                            {/*</Menu.Item>*/}
                        </Menu>
                    </Col>
                </Row>
            </Col>
        </Row>

    );
}

export default NavBar;
