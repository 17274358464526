export function goToSection(sectionId: string) {
    const sectionElement = document.getElementById(sectionId)
    if (sectionElement) {
        // sectionElement.scrollIntoView({behavior: 'smooth', block: 'start', inline: "start"})

        const headerOffset = 60;
        const elementPosition = sectionElement.offsetTop;
        const offsetPosition = elementPosition - headerOffset;

        window.scrollTo({
            top: offsetPosition,
            behavior: "smooth"
        });
        updateHash(sectionId)
    }
}

export function updateHash(sectionId: string) {

    if (window.history.replaceState) {
        window.history.replaceState(null, '', `#${sectionId}`);
    } else {
        window.location.hash = `#${sectionId}`;
    }
}

export function detectMobile() {
    const toMatch = [
        /Android/i,
        /webOS/i,
        /iPhone/i,
        /iPad/i,
        /iPod/i,
        /BlackBerry/i,
        /Windows Phone/i
    ];

    return toMatch.some((toMatchItem) => {
        return navigator.userAgent.match(toMatchItem);
    });
}

export function detectiOS() {
    return [
        'iPad Simulator',
        'iPhone Simulator',
        'iPod Simulator',
        'iPad',
        'iPhone',
        'iPod'
    ].includes(navigator.platform);
}
